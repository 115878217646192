
import * as storyblokRuntime$cYZZCwyuBT from '/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$YdhFdXMwyU from '/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 768,
    "md": 1024,
    "lg": 1200,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "infermedica.com",
    "a.storyblok.com"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$cYZZCwyuBT, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$YdhFdXMwyU, defaults: {} }
}
        